import {PriceReportEnvirionments} from './types';

// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment: PriceReportEnvirionments = {
  ENV_TYPE: 'dev',
  METADA_URL: 'https://api.dev.teasdev.com/esb/teas/api/',
  CATALOGUE_URL: 'https://api.dev.alpha-analytics.dev/alpha-catalogue/',
  FLAGSMITH_ENV: 'ieS6Un5oXjzKMAgJJ4cbPi',
  OMNETIC_API_URL: 'https://api.dev.omnetic.dev',
  OMNETIC_TOOLS_API_URL: 'https://tools.api.dev.omnetic.dev',
  GTM_CONTAINER_ID: null,
  E2E_API_KEY: '4318e70b-f163-4db7-a52b-e9ec523a33ab',
  GOOGLE_API_KEY: 'AIzaSyBihCGRjmRTKRDVd9MOmmyH_XxV165m8ok',
};
