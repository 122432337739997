import {IconButton} from 'platform/components';
import {
  Box,
  Clickable,
  Heading,
  Hide,
  HStack,
  Icon,
  Show,
  Stack,
  ThemeIconKey,
  useDevice,
} from 'platform/foundation';

import {ForwardedRef, forwardRef, ReactNode} from 'react';
import {useParams} from 'react-router-dom';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {Flag} from '../../../../components/Flag/Flag';
import {Collapse} from './components/Collapse';

interface VehicleInfoProps extends TestIdProps {
  children: ReactNode;
  icon: ThemeIconKey;
  heading: string;
  summary?: string;
}

export const VinDecoderCard = forwardRef(
  (props: VehicleInfoProps, ref: ForwardedRef<HTMLDivElement>) => {
    const device = useDevice();
    const [isExpanded, toggleIsExpanded] = useToggle(device !== 'mobile' && device !== 'tablet');
    const isPrintForced = useParams<{param?: string}>().param === 'pdf';

    return (
      <Box borderRadius="small" border="1px solid" borderColor="palettes.neutral.40.100" ref={ref}>
        <Clickable onClick={toggleIsExpanded} isDisabled={isPrintForced}>
          <Box padding={isPrintForced ? 8 : [3, 3, 8]}>
            <HStack spacing={4} align="center">
              <Icon value={props.icon} size={8} color="general.black" />
              <Box flex={1}>
                <Stack
                  spacing={[1, 1, 4]}
                  direction={['column', 'column', 'row']}
                  align={['flex-start', 'flex-start', 'center']}
                  justify="space-between"
                >
                  <Heading size={3}>{props.heading}</Heading>
                  <Show when={isNotNil(props.summary)}>
                    <Flag label={props.summary} data-testid={suffixTestId('summary', props)} />
                  </Show>
                </Stack>
              </Box>
              <Hide inPrinter when={isPrintForced}>
                <IconButton
                  size="small"
                  priority="default"
                  icon={isExpanded ? 'navigation/expand_less' : 'navigation/expand_more'}
                  data-testid={suffixTestId('expandButton', props)}
                />
              </Hide>
            </HStack>
          </Box>
        </Clickable>
        <Collapse isOpen={isPrintForced || isExpanded}>
          <Box
            padding={isPrintForced ? 8 : [3, 8]}
            borderTop="1px solid"
            borderColor="palettes.neutral.40.100"
          >
            {props.children}
          </Box>
        </Collapse>
      </Box>
    );
  }
);
